import { createAction } from "redux-actions";

import { OfficeRequest, OfficeResponse } from "../pages/Offices/models";
import ApiClient from "../services/ApiClient/api-client-services";
import { Action } from "../types/redux";

export enum ActionTypePrefixes {
  OFFICES = "OFFICES",
  ADD_OFFICE = "ADD_OFFICE",
  EDIT_OFFICE = "EDIT_OFFICE",
  DELETE_OFFICE = "DELETE_OFFICE"
}

export enum ActionTypeKeysOffice {
  OFFICES_PENDING = "OFFICES_PENDING",
  OFFICES_FULFILLED = "OFFICES_FULFILLED",
  OFFICES_REJECTED = "OFFICES_REJECTED",
  ADD_OFFICE_PENDING = "ADD_OFFICE_PENDING",
  ADD_OFFICE_FULFILLED = "ADD_OFFICE_FULFILLED",
  ADD_OFFICE_REJECTED = "ADD_OFFICE_REJECTED",
  EDIT_OFFICE_PENDING = "EDIT_OFFICE_PENDING",
  EDIT_OFFICE_FULFILLED = "EDIT_OFFICE_FULFILLED",
  EDIT_OFFICE_REJECTED = "EDIT_OFFICE_REJECTED",
  DELETE_OFFICE_PENDING = "DELETE_OFFICE_PENDING",
  DELETE_OFFICE_FULFILLED = "DELETE_OFFICE_FULFILLED",
  DELETE_OFFICE_REJECTED = "DELETE_OFFICE_REJECTED"
}

interface OfficesPayload {
  data: OfficeResponse[];
}

interface EditOfficePayload {
  data: number[];
  config: {
    data: string;
  };
}

interface DeleteOfficePayload {
  data: { todelete: number };
}

const getOfficesExecutor = (): Promise<OfficeResponse[]> =>
  ApiClient.loadOffices();

const addOfficeExecutor = (office: OfficeRequest): Promise<OfficeRequest> =>
  ApiClient.addOffice(office);

const editOfficeExecutor = (
  id: number,
  office: OfficeRequest
): Promise<OfficeRequest> => ApiClient.updateOffice(id, office);

const deleteOfficeExecutor = (officeId: number): Promise<OfficeRequest> =>
  ApiClient.deleteOffice(officeId);

export const getOfficesAction = createAction(
  ActionTypePrefixes.OFFICES,
  getOfficesExecutor
);

export const addOfficeAction = createAction(
  ActionTypePrefixes.ADD_OFFICE,
  addOfficeExecutor
);

export const editOfficeAction = createAction(
  ActionTypePrefixes.EDIT_OFFICE,
  editOfficeExecutor
);

export const deleteOfficeAction = createAction(
  ActionTypePrefixes.DELETE_OFFICE,
  deleteOfficeExecutor
);

export type ActionTypesOffice =
  | Action<ActionTypeKeysOffice.OFFICES_PENDING, undefined>
  | Action<ActionTypeKeysOffice.OFFICES_FULFILLED, OfficesPayload>
  | Action<ActionTypeKeysOffice.OFFICES_REJECTED, OfficesPayload>
  | Action<ActionTypeKeysOffice.ADD_OFFICE_PENDING, undefined>
  | Action<ActionTypeKeysOffice.ADD_OFFICE_FULFILLED, EditOfficePayload>
  | Action<ActionTypeKeysOffice.ADD_OFFICE_REJECTED, OfficesPayload>
  | Action<ActionTypeKeysOffice.EDIT_OFFICE_PENDING, undefined>
  | Action<ActionTypeKeysOffice.EDIT_OFFICE_FULFILLED, EditOfficePayload>
  | Action<ActionTypeKeysOffice.EDIT_OFFICE_REJECTED, OfficesPayload>
  | Action<ActionTypeKeysOffice.DELETE_OFFICE_PENDING, undefined>
  | Action<ActionTypeKeysOffice.DELETE_OFFICE_FULFILLED, DeleteOfficePayload>
  | Action<ActionTypeKeysOffice.DELETE_OFFICE_REJECTED, OfficesPayload>;
