import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import * as React from "react";

import ApiClient from "../../../services/ApiClient/api-client-services";
import { SERVICES_URL } from "../../../services/ApiClient/urls";
import useStyles from "./styles";

interface Response {
  data: {
    token: string;
  };
}

export interface ServiceKeyFormProps {
  service?: string;
}

const ServiceKeyForm: React.FC<ServiceKeyFormProps> = ({ service }) => {
  const classes = useStyles();
  const isNew = service === undefined;

  const [serviceName, setServiceName] = React.useState<string>(service || "");
  const [key, setKey] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [feedback, setFeedback] = React.useState<string | null>(null);

  const keyInput = React.useRef<HTMLInputElement>(null);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    if (isNew) {
      ApiClient.createAxiosClient()
        .post(
          ApiClient.createURL(SERVICES_URL),
          { name: serviceName },
          ApiClient.createAuthHeaders()
        )
        .then((response: Response) => {
          const { data } = response;
          if (typeof data === "object") {
            setKey(data.token);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      ApiClient.createAxiosClient()
        .patch(
          ApiClient.createURL(SERVICES_URL),
          { name: service },
          ApiClient.createAuthHeaders()
        )
        .then((response: Response) => {
          const { data } = response;
          if (typeof data === "object") {
            setKey(data.token);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleCopyToClipboard = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (!navigator.clipboard) {
      const text = keyInput.current;
      if (text === null) return;
      text.focus();
      text.select();
      document.execCommand("copy");

      return;
    }

    if (key !== null) copyToClipboard(key);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setKey(null);
      setFeedback("Copied to clipboard!");

      if (isNew) {
        setServiceName("");
      }
    });
  };

  const renderKey = (
    <TextField
      name="key"
      inputRef={keyInput}
      value={key}
      InputProps={{
        readOnly: true,
        fullWidth: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="copy to clipboard"
              onClick={handleCopyToClipboard}
            >
              <FileCopyIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );

  React.useEffect(() => {
    if (feedback !== null) {
      setTimeout(() => {
        setFeedback(null);
      }, 3000);
    }
  }, [feedback]);

  return (
    <form onSubmit={onSubmit}>
      <Box
        display="flex"
        alignItems="flex-end"
        flexWrap="wrap"
        className={classes.form}
      >
        <TextField
          name="service-name"
          value={serviceName}
          onChange={e => setServiceName(e.target.value)}
          label="Service Name"
          required={true}
          InputLabelProps={{
            required: false
          }}
          InputProps={{
            readOnly: !isNew
          }}
        />
        <Button variant="contained" color="primary" size="large" type="submit">
          {isNew ? "Generate key" : "Regenerate key"}
        </Button>
        {isLoading && <CircularProgress />}
        {feedback && <span>{feedback}</span>}
        <Box flexGrow={1}>{key !== null && renderKey}</Box>
      </Box>
    </form>
  );
};

export default ServiceKeyForm;
