import { Dispatch } from "redux";

import { logoutAction, verifyTokenAction } from "../../data/authenticate";
import { exchangeTokenAction } from "../../data/authenticate";
import { showErrorAction } from "../Toast/toastSlice";
import { ProtectedState } from "./reducer";

export interface StateProps {
  token?: string;
  isTokenExpired: boolean;
  refreshToken?: string;
  publicKey?: string;
}

export interface DispatchProps {
  verifyToken: (token: string, publicKey: string) => void;
  exchangeToken: (token: string) => void;
  logout: () => void;
  showError: (message: string) => void;
}

export const mapStateToProps = (protectedState: ProtectedState): StateProps => {
  return {
    token: protectedState.token,
    isTokenExpired: protectedState.isTokenExpired,
    refreshToken: protectedState.refreshToken,
    publicKey: protectedState.publicKey
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  verifyToken: (token: string, publicKey: string) => {
    dispatch(verifyTokenAction(token, publicKey));
  },
  exchangeToken: (refreshToken: string) => {
    dispatch(exchangeTokenAction(refreshToken));
  },
  logout: () => {
    dispatch(logoutAction());
  },
  showError: message => {
    dispatch(showErrorAction(message));
  }
});
