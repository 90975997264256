import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";

import { capitalize } from "../../utils/converterFunctions";

interface Props<T extends object> {
  rows: T[];
  cols: Array<Extract<keyof T, string>>;
}

function BasicTable<T extends object>(
  props: React.PropsWithChildren<Props<T>>
): React.ReactElement<any, any> | null {
  const { rows, cols } = props;

  if (rows.length === 0 || cols.length === 0) return null;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {cols.map(col => (
              <TableCell key={col}>{capitalize(col)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {cols.map(col => (
                <TableCell key={col}>{row[col]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BasicTable;
