import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "recompose";
import { createSelector } from "reselect";

import { protectedComponentHoc } from "../../components/ProtectedComponent";
import {
  DropoutsComponent,
  DropoutsInnerProps,
  DropoutsProps
} from "./component";
import { mapDispatchToProps, mapStateToProps } from "./redux";
import { dropoutsSelector } from "./selectors";
import { DropoutsStyleRules } from "./styles";

export const Dropouts = compose<DropoutsInnerProps, DropoutsProps>(
  connect(
    createSelector(dropoutsSelector, mapStateToProps),
    mapDispatchToProps
  ),
  withStyles(DropoutsStyleRules),
  protectedComponentHoc
)(DropoutsComponent);
