import { ActionTypeKeysOffice, ActionTypesOffice } from "../../data/offices";
import { OfficeResponse } from "./models";

export interface OfficesState {
  offices: OfficeResponse[];
  isLoading: boolean;
}

export const officesInitialState: OfficesState = {
  offices: [],
  isLoading: false
};

const editOffice = (offices: OfficeResponse[], requestData: string) => {
  const officeEdited = JSON.parse(requestData);
  const id: number = parseInt(Object.keys(officeEdited)[0]);

  const index = offices.findIndex(el => el.id === id);
  if (index === -1) {
    return offices;
  }

  const office = { id, ...officeEdited[id] };
  const updatedOffices = offices.slice();
  updatedOffices[index] = office;

  return updatedOffices;
};

const addOffice = (
  offices: OfficeResponse[],
  data: number[],
  requestData: string
) => {
  const id: number = data[0];
  if (!id) {
    return offices;
  }

  const officeAdded = JSON.parse(requestData)[0];
  const newOffice: OfficeResponse = { id, ...officeAdded };

  return offices.concat([newOffice]);
};

const deleteOffice = (offices: OfficeResponse[], id: number) => {
  if (!id) {
    return offices;
  }

  const index = offices.findIndex(el => el.id === id);
  if (index === -1) {
    return offices;
  }

  const officesLeft = offices.slice();
  officesLeft.splice(index, 1);

  return officesLeft;
};

export const officesReducer = (
  state: OfficesState = officesInitialState,
  action: ActionTypesOffice
) => {
  switch (action.type) {
    case ActionTypeKeysOffice.OFFICES_PENDING: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ActionTypeKeysOffice.OFFICES_FULFILLED: {
      return {
        ...state,
        isLoading: false,
        offices: Array.isArray(action.payload.data)
          ? action.payload.data
          : state.offices
      };
    }
    case ActionTypeKeysOffice.ADD_OFFICE_FULFILLED: {
      return {
        ...state,
        offices: addOffice(
          state.offices,
          action.payload.data,
          action.payload.config.data
        )
      };
    }
    case ActionTypeKeysOffice.EDIT_OFFICE_FULFILLED: {
      return {
        ...state,
        offices: editOffice(state.offices, action.payload.config.data)
      };
    }
    case ActionTypeKeysOffice.DELETE_OFFICE_FULFILLED: {
      return {
        ...state,
        offices: deleteOffice(state.offices, action.payload.data.todelete)
      };
    }
    default:
      return state;
  }
};
