import { ActionTypeKeys, ActionTypes } from "../../data/types";

export interface ProtectedState {
  token?: string;
  isTokenExpired: boolean;
  refreshToken?: string;
  publicKey?: string;
}

export const protectedInitialState: ProtectedState = {
  token: undefined,
  isTokenExpired: false,
  refreshToken: undefined,
  publicKey: undefined
};

export const protectedReducer = (
  state: ProtectedState = protectedInitialState,
  action: ActionTypes
) => {
  switch (action.type) {
    case ActionTypeKeys.LOGIN_FULFILLED: {
      return {
        ...state,
        token: action.payload.data.token,
        refreshToken: action.payload.data.refreshToken
      };
    }
    case ActionTypeKeys.LOGOUT: {
      return protectedInitialState;
    }
    case ActionTypeKeys.REQUEST_PUBLIC_KEY_FULFILLED: {
      return {
        ...state,
        publicKey: action.payload.data[0].content
      };
    }
    case ActionTypeKeys.VERIFY_TOKEN: {
      return {
        ...state,
        token: action.payload.success ? state.token : undefined,
        isTokenExpired:
          !action.payload.success && action.payload.name === "TokenExpiredError"
      };
    }
    case ActionTypeKeys.EXPIRE_TOKEN: {
      return {
        ...state,
        isTokenExpired: true
      };
    }
    case ActionTypeKeys.EXCHANGE_TOKEN_FULFILLED: {
      return {
        ...state,
        token: action.payload.data.token,
        refreshToken: action.payload.data.refreshToken,
        isTokenExpired: false
      };
    }
    case ActionTypeKeys.EXCHANGE_TOKEN_REJECTED: {
      return protectedInitialState;
    }
    default:
      return state;
  }
};
