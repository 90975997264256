import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  select: {
    marginTop: "20px",
    maxWidth: "130px",
    width: "100%",
    marginLeft: "15px"
  },
  pageTitle: {
    marginTop: "40px"
  }
});

export default useStyles;
