import { ActionTypeKeysOffice, ActionTypesOffice } from "../../data/offices";
import { OfficeResponse } from "../Offices/models";
import { ActionTypeKeys, ActionTypes } from "./actions";
import { AddUserResponse, UserRawResponse, UserResponse } from "./models";

export interface UsersState {
  users: UserResponse[];
  offices: OfficeResponse[];
  isLoading: boolean;
}

export const usersInitialState: UsersState = {
  users: [],
  offices: [],
  isLoading: false
};

const addUser = (users: UserResponse[], data: AddUserResponse) => {
  const newUser = data.user;
  if (!data.outcome || !newUser) {
    return users;
  }
  const transformedUser = transformResponse([newUser]);

  return users.concat(transformedUser);
};

const editUser = (users: UserResponse[], requestData: string) => {
  const userEdited = JSON.parse(requestData);
  const id: number = parseInt(Object.keys(userEdited)[0]);

  const index = users.findIndex(el => el.id === id);
  if (index === -1) {
    return users;
  }

  const user = { id, ...userEdited[id] };
  const updatedUsers = users.slice();
  updatedUsers[index] = user;

  return updatedUsers;
};

const deleteUser = (users: UserResponse[], id: number) => {
  if (!id) {
    return users;
  }

  const index = users.findIndex(el => el.id === id);
  if (index === -1) {
    return users;
  }

  const usersLeft = users.slice();
  usersLeft.splice(index, 1);

  return usersLeft;
};

const transformResponse = (users: UserRawResponse[]): UserResponse[] =>
  users.map(item => {
    const user = JSON.parse(JSON.stringify(item));
    if (!user.offices) {
      user.offices = [];
    } else {
      user.offices = user.offices.map((office: OfficeResponse) => office.id);
    }
    return user;
  });

export const usersReducer = (
  state: UsersState = usersInitialState,
  action: ActionTypes | ActionTypesOffice
) => {
  switch (action.type) {
    case ActionTypeKeys.USERS_PENDING: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ActionTypeKeys.USERS_FULFILLED: {
      return {
        ...state,
        isLoading: false,
        users: Array.isArray(action.payload.data)
          ? transformResponse(action.payload.data)
          : state.users
      };
    }
    case ActionTypeKeys.USERS_REJECTED: {
      return {
        ...state,
        isLoading: false
      };
    }
    case ActionTypeKeys.ADD_USER_FULFILLED: {
      return {
        ...state,
        users: addUser(state.users, action.payload.data)
      };
    }
    case ActionTypeKeys.EDIT_USER_FULFILLED: {
      return {
        ...state,
        users: editUser(state.users, action.payload.config.data)
      };
    }
    case ActionTypeKeys.DELETE_USER_FULFILLED: {
      return {
        ...state,
        users: deleteUser(state.users, parseInt(action.meta.id))
      };
    }
    case ActionTypeKeysOffice.OFFICES_FULFILLED: {
      return {
        ...state,
        offices: Array.isArray(action.payload.data)
          ? action.payload.data
          : state.offices
      };
    }
    default:
      return state;
  }
};
