import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  pageTitle: {
    marginTop: "40px"
  },
  formControl: {
    minWidth: "200px",
    margin: "20px 20px 20px 0"
  },
  button: {
    color: "#fff",
    backgroundColor: "#18BC9C",
    borderRadius: "4px",
    fontSize: "16px",
    border: "none",
    padding: "10px",
    width: "100%",
    marginTop: "30px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#149a80"
    },
    "&:disabled": {
      cursor: "default",
      pointerEvents: "none",
      color: "rgba(0, 0, 0, 0.26)"
    }
  }
});

export default useStyles;
