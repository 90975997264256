import { createAction } from "redux-actions";

import { Policy } from "../pages/Dropouts/models";
import {
  PercentageRequest,
  PercentageResponse
} from "../pages/ProposalPercentage/models";
import ApiClient from "../services/ApiClient/api-client-services";
import { Action } from "../types/redux";

export enum ActionTypePrefixes {
  EDIT_PERCENTAGE = "EDIT_PERCENTAGE",
  PROPOSAL_POLICY_TYPES = "PROPOSAL_POLICY_TYPES"
}

export enum ActionTypeKeysPercentage {
  EDIT_PERCENTAGE_PENDING = "EDIT_PERCENTAGE_PENDING",
  EDIT_PERCENTAGE_FULFILLED = "EDIT_PERCENTAGE_FULFILLED",
  EDIT_PERCENTAGE_REJECTED = "EDIT_PERCENTAGE_REJECTED",
  PROPOSAL_POLICY_TYPES_PENDING = "PROPOSAL_POLICY_TYPES_PENDING",
  PROPOSAL_POLICY_TYPES_FULFILLED = "PROPOSAL_POLICY_TYPES_FULFILLED",
  PROPOSAL_POLICY_TYPES_REJECTED = "PROPOSAL_POLICY_TYPES_REJECTED"
}

interface PercentagesPayload {
  data: PercentageResponse[];
}

interface EditPercentagePayload {
  data: Policy;
}

interface PolicyTypesPayload {
  data: Policy[];
}

const getPolicyTypesExecutor = () => ApiClient.getPolicyTypes();
export const getPolicyTypesAction = createAction(
  ActionTypePrefixes.PROPOSAL_POLICY_TYPES,
  getPolicyTypesExecutor
);
const editPercentage = (policy: Policy): Promise<PercentageRequest> =>
  ApiClient.updatePercentage(policy);

export const editPercentageAction = createAction(
  ActionTypePrefixes.EDIT_PERCENTAGE,
  editPercentage
);

export type ActionTypesPercentage =
  | Action<ActionTypeKeysPercentage.EDIT_PERCENTAGE_PENDING, undefined>
  | Action<
      ActionTypeKeysPercentage.EDIT_PERCENTAGE_FULFILLED,
      EditPercentagePayload
    >
  | Action<
      ActionTypeKeysPercentage.EDIT_PERCENTAGE_REJECTED,
      PercentagesPayload
    >
  | Action<ActionTypeKeysPercentage.PROPOSAL_POLICY_TYPES_PENDING, undefined>
  | Action<
      ActionTypeKeysPercentage.PROPOSAL_POLICY_TYPES_FULFILLED,
      PolicyTypesPayload
    >
  | Action<
      ActionTypeKeysPercentage.PROPOSAL_POLICY_TYPES_REJECTED,
      PolicyTypesPayload
    >;
