import Snackbar from "@material-ui/core/Snackbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { toastSelector } from "./selectors";
import useStyles from "./styles";
import { closeToastAction } from "./toastSlice";

export const ToastComponent: React.FC<{}> = () => {
  const classes = useStyles();
  const { message, open, status } = useSelector(toastSelector);
  const contentClasses = {
    root: classes[status]
  };
  const dispatch = useDispatch();

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={6000}
      open={open && !!message}
      onClose={() => {
        dispatch(closeToastAction());
      }}
      message={message}
      ContentProps={{ classes: contentClasses }}
    />
  );
};
