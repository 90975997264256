import { Typography, WithStyles } from "@material-ui/core";
import { Column } from "material-table";
import React, { useEffect, useState } from "react";

import { Multiselect } from "../../components/Multiselect";
import { Table } from "../../components/Table";
import ApiClient from "../../services/ApiClient/api-client-services";
import { LookupItem, UserRequest, UserResponse } from "./models";
import { DispatchProps, StateProps } from "./redux";
import { UsersClassKey } from "./styles";

export interface UsersProps {}

export interface UsersInnerProps
  extends UsersProps,
    StateProps,
    DispatchProps,
    WithStyles<UsersClassKey> {}

interface ColumnsState {
  columns: Column<UserResponse>[];
}

interface tableData {
  tableData: {
    id: number;
  };
}

interface RowData extends UserResponse, tableData {}

export const UsersComponent: React.FC<UsersInnerProps> = ({
  getUsers,
  getOffices,
  addUser,
  deleteUser,
  editUser,
  isLoading,
  users,
  offices,
  classes
}) => {
  const [roles, setRoles] = useState<LookupItem>({});

  const loadRoles = () => {
    ApiClient.getAllRoles()
      .then(({ data }) => {
        setRoles(data);
      })
      .catch(error => {});
  };

  const officesLookup: LookupItem = offices.reduce((acc, e) => {
    return {
      [e.id]: e.name,
      ...acc
    };
  }, {});

  const columnsWithRolesLookup: ColumnsState = {
    columns: [
      { field: "id", title: "ID", editable: "never", filtering: false },
      {
        field: "roleId",
        title: "Role",
        lookup: roles
      },
      { field: "firstName", title: "First Name" },
      { field: "lastName", title: "Last Name" },
      { field: "partnerInfo", title: "Partner Info", filtering: false },
      { field: "email", title: "Email" },
      {
        field: "offices",
        title: "Offices",
        lookup: officesLookup,
        editComponent: props => {
          return (
            <Multiselect
              items={officesLookup}
              value={props.value || []}
              onChange={props.onChange}
              name="office-filter"
              label="Office"
            />
          );
        },

        render: (rowData: UserResponse) =>
          rowData.offices.map(id => (
            <Typography key={id} gutterBottom variant="inherit" component="p">
              {officesLookup[id]}
            </Typography>
          )),

        customFilterAndSearch: (terms: string[], rowData: UserResponse) => {
          if (terms.length > 0) {
            const ids = terms.map(term => parseInt(term));
            const userShouldRemain = rowData.offices.some((id: number) =>
              ids.includes(id)
            );
            return userShouldRemain;
          }

          return true;
        }
      }
    ]
  };

  useEffect(() => {
    loadRoles();
  }, []);

  useEffect(() => {
    getUsers();
    getOffices();
  }, [getOffices, getUsers]);

  const handleAddUser = (newData: UserResponse) => {
    const { ...user }: UserRequest = newData;
    return addUser(user);
  };

  const handleDeleteUser = (oldData: UserResponse) =>
    deleteUser(oldData.id.toString());
  const handleEditUser = (userNewData: UserResponse, userOldData: RowData) =>
    editUser(userNewData.id, userNewData);

  return (
    <>
      <Typography
        className={classes.pageTitle}
        gutterBottom
        align="center"
        variant="h4"
        component="h5"
      >
        Users
      </Typography>
      {columnsWithRolesLookup ? (
        <Table
          hasEdit={true}
          hasDelete={true}
          handleAddRow={handleAddUser}
          handleEditRow={handleEditUser}
          handleDeleteRow={handleDeleteUser}
          dataTable={users}
          columnsData={columnsWithRolesLookup.columns}
          isLoading={isLoading}
        />
      ) : undefined}
    </>
  );
};
