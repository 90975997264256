import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  form: {
    marginBottom: "50px",
    marginTop: "20px"
  },
  status: {
    marginLeft: "8px"
  },
  row: {
    marginBottom: "16px"
  }
});

export default useStyles;
