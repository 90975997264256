import axios, { AxiosInstance } from "axios";

import {
  showErrorAction,
  showSuccessAction
} from "../../components/Toast/toastSlice";
import { expireAction } from "../../data/authenticate";
import {
  CountryRequest,
  DeleteCountryRequest,
  UpdateCountryRequest
} from "../../pages/Countries/models";
import {
  DamageCostRequest,
  DamageCostResponse
} from "../../pages/DamageCosts/models";
import {
  DropoutRawResponse,
  DropoutRequest,
  Policy
} from "../../pages/Dropouts/models";
import { OfficeRequest, OfficeResponse } from "../../pages/Offices/models";
import { PercentageRequest } from "../../pages/ProposalPercentage/models";
import {
  RolesResponse,
  UserRawResponse,
  UserRequest,
  UserResponse
} from "../../pages/Users/models";
import { store } from "../../store";
import { PublicKeyResponse } from "../../types/responseTypes";
import {
  AUTH_URL,
  COUNTRY_BASE_URL,
  DROPOUTS_URL,
  EXCHANGE_TOKEN,
  INSURER_BASE_URL,
  LANGUAGES_URL,
  OFFICES_BASE_URL,
  PERCENTAGE_UPDATE_URL,
  POLICY_TYPES_URL,
  PUBLIC_KEY_URL,
  REPAIR_COST_URL,
  ROLES_URL,
  TRANSLATIONS,
  TRANSLATIONS_LIST,
  USERS_URL
} from "./urls";

class EmsApiClient {
  axiosInstance: AxiosInstance | undefined = undefined;
  token: string | undefined = undefined;

  setToken(token?: string) {
    this.token = token;
  }

  createAuthHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE"
      }
    };
  }

  getBaseUrl() {
    let baseURL = process.env.REACT_APP_API_URL;

    if (baseURL === "" || typeof baseURL === "undefined") {
      baseURL = "https://develop-docker.emsclaimsengine.com";
    }

    if (baseURL === "https://uclaim.emsclaimsengine.com") {
      baseURL = "https://schade.uclaim.eu";
    }

    return baseURL;
  }

  createURL(link: string) {
    return this.getBaseUrl() + "/external-api/" + link;
  }

  createAxiosClient() {
    if (typeof this.axiosInstance === "undefined") {
      this.axiosInstance = axios.create({
        validateStatus: function (status) {
          return status >= 200 && status < 400;
        }
      });
      this.axiosInstance.interceptors.response.use(
        response => {
          const {
            config: { method }
          } = response;
          switch (method) {
            case "put": {
              store.dispatch(showSuccessAction("Item updated"));
              break;
            }
            case "delete": {
              store.dispatch(showSuccessAction("Item deleted"));
              break;
            }
          }

          return response;
        },
        error => {
          if (error.response) {
            const { data, status } = error.response;
            if (status === 403) {
              store.dispatch(expireAction());
              store.dispatch(showErrorAction("Token expired"));
            } else {
              const message: string = data.message || "An error has occured.";
              store.dispatch(showErrorAction(message));
            }
          }

          return Promise.reject(error);
        }
      );
    }
    return this.axiosInstance;
  }

  requestAuth = (username: string, password: string) => {
    const config = {
      headers: { "content-type": "multipart/form-data" }
    };

    const credentialsFormData = new FormData();
    credentialsFormData.set("username", username);
    credentialsFormData.set("password", password);

    return this.createAxiosClient().post(
      this.createURL(AUTH_URL),
      credentialsFormData,
      config
    );
  };

  requestPublicKey = (): Promise<PublicKeyResponse[]> =>
    this.createAxiosClient().get(this.createURL(PUBLIC_KEY_URL));

  exchangeToken = (refreshToken: string) =>
    this.createAxiosClient().post(this.createURL(EXCHANGE_TOKEN), {
      eToken: refreshToken
    });

  getAllDropouts = () =>
    this.createAxiosClient().get(
      this.createURL(DROPOUTS_URL),
      this.createAuthHeaders()
    );

  addDropout = (dropout: DropoutRequest) =>
    this.createAxiosClient().post(
      this.createURL(DROPOUTS_URL),
      dropout,
      this.createAuthHeaders()
    );

  deleteDropout = (id: number) =>
    this.createAxiosClient().delete(
      this.createURL(`${DROPOUTS_URL}/${id}`),
      this.createAuthHeaders()
    );

  editDropout = (dropout: DropoutRawResponse) => {
    const id = dropout.id;
    return this.createAxiosClient().put(
      this.createURL(`${DROPOUTS_URL}/${id}`),
      dropout,
      this.createAuthHeaders()
    );
  };

  getAllUsers = (): Promise<UserRawResponse[]> => {
    return this.createAxiosClient().get(
      this.createURL(USERS_URL),
      this.createAuthHeaders()
    );
  };

  getAllRoles = (): Promise<RolesResponse> => {
    return this.createAxiosClient().get(
      this.createURL(ROLES_URL),
      this.createAuthHeaders()
    );
  };

  addUser = (user: UserRequest): Promise<UserResponse> => {
    return this.createAxiosClient().post(
      this.createURL(USERS_URL),
      user,
      this.createAuthHeaders()
    );
  };

  deleteUser = (id: string) =>
    this.createAxiosClient().delete(
      this.createURL(`${USERS_URL}/${id}`),
      this.createAuthHeaders()
    );

  updateUser = (id: number, user: UserRequest): Promise<UserResponse> => {
    const payload = { [id]: user };
    return this.createAxiosClient().put(
      this.createURL(USERS_URL),
      payload,
      this.createAuthHeaders()
    );
  };

  getUser = (id: number): Promise<UserResponse> => {
    return this.createAxiosClient().get(
      this.createURL(USERS_URL) + "/" + id,
      this.createAuthHeaders()
    );
  };

  loadRepairCosts = (): Promise<DamageCostResponse[]> => {
    return this.createAxiosClient().get(
      this.createURL(REPAIR_COST_URL),
      this.createAuthHeaders()
    );
  };

  updateRepairCost = (
    id: number,
    damageCost: DamageCostRequest
  ): Promise<DamageCostRequest> => {
    const updateRepair = { [`${id}`]: damageCost };
    return this.createAxiosClient().put(
      this.createURL(REPAIR_COST_URL),
      updateRepair,
      this.createAuthHeaders()
    );
  };

  deleteRepairCost = (damageCostId: string): Promise<string> => {
    const deleteDamageCost = [damageCostId];
    return this.createAxiosClient().delete(
      this.createURL(REPAIR_COST_URL) + "/" + JSON.stringify(deleteDamageCost),
      this.createAuthHeaders()
    );
  };

  addRepairCost = (
    damageCost: DamageCostRequest
  ): Promise<DamageCostRequest> => {
    const addRepair = [damageCost];
    return this.createAxiosClient().post(
      this.createURL(REPAIR_COST_URL),
      addRepair,
      this.createAuthHeaders()
    );
  };

  loadInsurers = () => {
    return this.createAxiosClient().get(
      this.createURL(INSURER_BASE_URL),
      this.createAuthHeaders()
    );
  };

  loadCountries = (insurerId: string) => {
    return this.createAxiosClient().get(
      this.createURL(COUNTRY_BASE_URL) + `/${insurerId}`,
      this.createAuthHeaders()
    );
  };

  updateCountry = ({ insurerId, id, country }: UpdateCountryRequest) => {
    const updatedCountry = { [id]: country };
    return this.createAxiosClient().put(
      this.createURL(COUNTRY_BASE_URL) + `/${insurerId}`,
      updatedCountry,
      this.createAuthHeaders()
    );
  };

  deleteCountry = ({ insurerId, id }: DeleteCountryRequest) => {
    return this.createAxiosClient().delete(
      this.createURL(`${COUNTRY_BASE_URL}/${insurerId}/${id}`),
      this.createAuthHeaders()
    );
  };

  addCountry = (country: CountryRequest) => {
    return this.createAxiosClient()
      .post(
        this.createURL(COUNTRY_BASE_URL) + `/${country.insurerId}`,
        [country.country],
        this.createAuthHeaders()
      )
      .then(response => {
        store.dispatch(showSuccessAction("Country added successfully"));

        return response;
      });
  };

  loadOffices = (): Promise<OfficeResponse[]> => {
    return this.createAxiosClient().get(
      this.createURL(OFFICES_BASE_URL),
      this.createAuthHeaders()
    );
  };

  updateOffice = (
    id: number,
    office: OfficeRequest
  ): Promise<OfficeRequest> => {
    const updatedOffice = { [id]: office };
    return this.createAxiosClient().put(
      this.createURL(OFFICES_BASE_URL),
      updatedOffice,
      this.createAuthHeaders()
    );
  };

  deleteOffice = (officeId: number): Promise<OfficeRequest> => {
    const deletedOffice = { [`todelete`]: officeId };
    return this.createAxiosClient().delete(
      this.createURL(OFFICES_BASE_URL) + `/${JSON.stringify(deletedOffice)}`,
      this.createAuthHeaders()
    );
  };

  addOffice = (office: OfficeRequest): Promise<OfficeRequest> => {
    const officeToBeAdded = [office];
    return this.createAxiosClient().post(
      this.createURL(OFFICES_BASE_URL),
      officeToBeAdded,
      this.createAuthHeaders()
    );
  };

  getPolicyTypes = () =>
    this.createAxiosClient().get(
      this.createURL(POLICY_TYPES_URL),
      this.createAuthHeaders()
    );

  uploadData = (data: FormData, url: string) =>
    this.createAxiosClient()
      .post(this.createURL(url), data, this.createAuthHeaders())
      .then(response => {
        const {
          data: { message }
        } = response;
        if (message) {
          store.dispatch(showSuccessAction(message));
        }
        return response;
      });

  uploadSingleFile = (file: File, url: string) => {
    let data = new FormData();
    data.append("file", file);
    return this.createAxiosClient()
      .post(this.createURL(url), data, this.createAuthHeaders())
      .then(response => {
        const {
          data: { message }
        } = response;
        if (typeof message === "string") {
          store.dispatch(showSuccessAction(message));
        }
        return response;
      });
  };

  fetchLanguages = () =>
    this.createAxiosClient().get(
      this.createURL(LANGUAGES_URL),
      this.createAuthHeaders()
    );

  fetchTranslations = (languages: number[]) =>
    this.createAxiosClient().post(
      this.createURL(TRANSLATIONS_LIST),
      languages,
      this.createAuthHeaders()
    );

  editTranslation = (translationId: number, text: string) =>
    this.createAxiosClient().put(
      this.createURL(`${TRANSLATIONS}/${translationId}`),
      { text },
      this.createAuthHeaders()
    );

  addTranslation = (data: object) =>
    this.createAxiosClient().post(
      this.createURL(TRANSLATIONS),
      data,
      this.createAuthHeaders()
    );

  deleteTranslation = (translationId: number) =>
    this.createAxiosClient().delete(
      this.createURL(`${TRANSLATIONS}/${translationId}`),
      this.createAuthHeaders()
    );

  updatePercentage = (percentage: Policy): Promise<PercentageRequest> => {
    const updatedPercentage = {
      policy_type: percentage.name,
      proposal_percentage: percentage.percentage
    };
    return this.createAxiosClient().put(
      this.createURL(PERCENTAGE_UPDATE_URL),
      updatedPercentage,
      this.createAuthHeaders()
    );
  };
}

const ApiClient = new EmsApiClient();
export default ApiClient;
