import { AnyAction, AsyncThunk } from "@reduxjs/toolkit";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;

export const isPendingAction = (action: AnyAction): action is PendingAction =>
  action.type.endsWith("/pending");

export const isRejectedAction = (action: AnyAction): action is RejectedAction =>
  action.type.endsWith("/rejected");
