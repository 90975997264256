import { Button } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { logoutAction } from "../../../../data/authenticate";
import { loginSelector } from "../../../../pages/Login/selectors";
import { DropDownMenu } from "../../../DropdownMenu";
import useStyles from "./styles";

export const ProtectedMenuComponent: React.FC<{}> = () => {
  const classes = useStyles();
  const { role } = useSelector(loginSelector);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuItems, setMenuItems] = useState({
    list: [{ label: "", url: "" }]
  });

  const dispatch = useDispatch();

  const handleDropdownList = (event: React.MouseEvent<HTMLButtonElement>) => {
    const linkList = {
      list: [
        { label: "List Users", url: "/users" },
        { label: "List Insured", url: "/insured" }
      ]
    };
    setAnchorEl(event.currentTarget);
    setMenuItems(linkList);
  };

  const handleDropdownImports = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const linkList = {
      list: [
        { label: "Import Auto Insurers", url: "/auto-insurers" },
        { label: "Import Bonus Malus", url: "/bonus-malus" },
        { label: "Import Coverages", url: "/coverages" },
        { label: "Import Depreciation List", url: "depreciation-list" },
        { label: "Import Excluded Conditions", url: "condition-code-filters" },
        { label: "Import Geolocation", url: "/geolocation" },
        { label: "Import Insurer List", url: "/insurer-list" },
        { label: "Import Repair Costs", url: "/import-repair-costs" },
        { label: "Import Translations", url: "/import-translations" }
      ]
    };
    setAnchorEl(event.currentTarget);
    setMenuItems(linkList);
  };

  const handleDropdownEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    const linkList = {
      list: [
        { label: "Edit Amount Limits", url: "/amount-limit" },
        { label: "Edit Repair Costs", url: "/damage-costs" },
        { label: "Edit Countries", url: "/countries" },
        { label: "Edit Dropouts", url: "/dropouts" },
        ...(role === "superadmin"
          ? [
              { label: "Edit Proposal Percentage", url: "/percentages" },
              {
                label: "Edit Translations",
                url: "/translations"
              }
            ]
          : [])
      ]
    };
    setAnchorEl(event.currentTarget);
    setMenuItems(linkList);
  };

  const handleDropdownPolicies = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const linkList = {
      list: [
        { label: "Policy DB", url: "/policy-check/db" },
        { label: "Policy Files", url: "/policy-check/files" },
        { label: "Policy Mappings", url: "/policy-check/mappings" }
      ]
    };
    setAnchorEl(event.currentTarget);
    setMenuItems(linkList);
  };

  const handleCloseSubmenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button className={classes.dropdown} onClick={handleDropdownList}>
        Lists
        <ArrowDropDownIcon />
      </Button>
      <Button className={classes.dropdown} onClick={handleDropdownImports}>
        Imports
        <ArrowDropDownIcon />
      </Button>
      <Button className={classes.dropdown} onClick={handleDropdownEdit}>
        Edit
        <ArrowDropDownIcon />
      </Button>
      <Link className={classes.link} to="/offices">
        Offices
      </Link>
      {role === "superadmin" && (
        <Button className={classes.dropdown} onClick={handleDropdownPolicies}>
          Policies
          <ArrowDropDownIcon />
        </Button>
      )}
      <Link className={classes.link} to="/service-keys">
        Service Keys
      </Link>

      <Button
        className={classNames(classes.link, classes.alignRight)}
        onClick={() => dispatch(logoutAction())}
      >
        Log out
      </Button>
      <DropDownMenu
        menuItems={menuItems}
        anchorRef={anchorEl}
        handleClose={handleCloseSubmenu}
      />
    </>
  );
};
