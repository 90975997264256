import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  dropdown: {
    padding: "8px 8px 5px 8px",
    color: "#fff",
    textDecoration: "none",
    fontSize: "16px",
    lineHeight: "20px",
    textTransform: "capitalize",
    minWidth: "initial",
    position: "relative",
    "&:before": {
      width: 0,
      height: 0,
      borderLeft: "6px solid transparent",
      borderRight: "6px solid transparent",
      borderTop: "6px solid #f00",
      content: "",
      position: "absolute",
      left: "0px",
      bottom: "0px"
    }
  },
  link: {
    padding: "8px",
    color: "#fff",
    textDecoration: "none",
    fontSize: "16px",
    lineHeight: "20px",
    textTransform: "capitalize"
  },
  alignRight: {
    marginLeft: "auto"
  }
});

export default useStyles;
