import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "recompose";
import { createSelector } from "reselect";

import { protectedComponentHoc } from "../../components/ProtectedComponent";
import {
  PercentageComponent,
  PercentagesInnerProps,
  PercentagesProps
} from "./component";
import { mapDispatchToProps, mapStateToProps } from "./redux";
import { percentageSelector } from "./selectors";
import { PercentagesStyleRules } from "./styles";

export const ProposalPercentage = compose<
  PercentagesInnerProps,
  PercentagesProps
>(
  connect(
    createSelector(percentageSelector, mapStateToProps),
    mapDispatchToProps
  ),
  withStyles(PercentagesStyleRules),
  protectedComponentHoc
)(PercentageComponent);
