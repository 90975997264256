import { Dispatch } from "redux";

import { AsyncDispatch } from "../../types/redux";
import {
  addDamageCostsAction,
  deleteDamageCostsAction,
  editDamageCostsAction,
  getDamageCostsAction
} from "./actions";
import { DamageCostRequest } from "./models";
import { DamageCostsState } from "./reducer";

export interface DispatchProps {
  getDamageCosts: () => void;
  editDamageCosts: (
    id: number,
    damageCost: DamageCostRequest
  ) => Promise<AsyncDispatch>;
  addDamageCosts: (damageCost: DamageCostRequest) => Promise<AsyncDispatch>;
  deleteDamageCosts: (damageCostId: string) => Promise<AsyncDispatch>;
}

export interface StateProps extends DamageCostsState {}

export const mapStateToProps = (
  damageCostsState: DamageCostsState
): StateProps => {
  return {
    damageCosts: damageCostsState.damageCosts,
    isLoading: damageCostsState.isLoading
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getDamageCosts: () => {
    dispatch(getDamageCostsAction());
  },
  editDamageCosts: (id, damageCost) =>
    dispatch(editDamageCostsAction(id, damageCost)),
  addDamageCosts: damageCost => dispatch(addDamageCostsAction(damageCost)),
  deleteDamageCosts: damageCostId =>
    dispatch(deleteDamageCostsAction(damageCostId))
});
