import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  title: {
    margin: "20px 0px"
  },
  button: {
    color: "#fff",
    backgroundColor: "#18BC9C",
    border: "none",
    padding: "10px",
    width: "100%",
    marginTop: "30px",
    cursor: "pointer",
    borderRadius: "4px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#149a80"
    }
  }
});

export default useStyles;
