import { Button } from "@material-ui/core";
import useAxios from "axios-hooks";
import * as React from "react";

import { CLEAR_CACHE } from "../../services/ApiClient/urls";

const ClearTranslationsCache: React.FC<{}> = () => {
  const [{ loading }, clearCache] = useAxios(
    {
      url: CLEAR_CACHE,
      method: "POST"
    },
    { manual: true }
  );

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      disabled={loading}
      onClick={() => {
        clearCache();
      }}
    >
      {loading ? "…" : "Clear Cache"}
    </Button>
  );
};

export default ClearTranslationsCache;
