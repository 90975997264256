import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Typography
} from "@material-ui/core";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import TranslateIcon from "@material-ui/icons/Translate";
import useAxios from "axios-hooks";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { ClearTranslationsCache } from "../../components/ClearTranslationsCache";
import { Customer } from "../../services/ApiClient/models";
import {
  CUSTOMERS_URL,
  TRANSLATIONS_LIST
} from "../../services/ApiClient/urls";
import { loginSelector } from "../Login/selectors";
import GenericTranslations from "./components/GenericTranslations";
import OverrideTranslations from "./components/OverrideTranslations";
import { fetchLanguages } from "./languagesSlice";
import { TranslationListResponse } from "./model";
import { languagesSelector } from "./selectors";

export const TranslationsComponent: React.FC<{}> = () => {
  const { role } = useSelector(loginSelector);
  const [isDefaultSelected, setIsDefaultSelected] = React.useState(true);
  const { defaultLanguage } = useSelector(languagesSelector);
  const [selectedLang, setSelectedLang] = React.useState<number[]>([
    defaultLanguage
  ]);
  const [
    { data: rawData, loading: translationLoading, error: translationError },
    translationRefetch
  ] = useAxios<TranslationListResponse[]>({
    url: TRANSLATIONS_LIST,
    method: "POST",
    data: selectedLang
  });
  const [
    { data: customerList = [], loading: customerLoading, error: customerError },
    customerRefetch
  ] = useAxios<Customer[]>(CUSTOMERS_URL);

  const genericTranslationsProps = {
    translationsList: { data: rawData, translationLoading, translationError },
    translationRefetch,
    customerRefetch,
    selectedLang
  };

  const overrideTranslationsProps = {
    translationsList: { data: rawData, translationLoading, translationError },
    translationRefetch,
    customerList,
    customerError,
    customerLoading,
    selectedLang
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchLanguages());
  }, [dispatch]);

  if (role !== "superadmin") {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Container>
      {translationError && translationError.response?.data}
      <Box my={4} display="flex" flexDirection="column" alignItems="center">
        <Typography align="center" variant="h4" component="h1">
          Translations
        </Typography>
        <ButtonGroup
          color="primary"
          size="large"
          aria-label="editor type"
          disableElevation={true}
        >
          <Button
            id="generic"
            variant={isDefaultSelected ? "contained" : undefined}
            startIcon={<TranslateIcon />}
            onClick={() => {
              setIsDefaultSelected(true);
            }}
          >
            Edit Defaults
          </Button>
          <Button
            id="overrides"
            variant={isDefaultSelected ? undefined : "contained"}
            startIcon={<AccountTreeIcon />}
            onClick={() => {
              setIsDefaultSelected(false);
            }}
          >
            Edit Overrides
          </Button>
        </ButtonGroup>
      </Box>
      {isDefaultSelected ? (
        <GenericTranslations
          {...genericTranslationsProps}
          onChange={setSelectedLang}
        />
      ) : (
        <OverrideTranslations
          {...overrideTranslationsProps}
          onChange={setSelectedLang}
        />
      )}
      <Box textAlign="center" my={2}>
        <ClearTranslationsCache />
      </Box>
    </Container>
  );
};
