import { createSlice } from "@reduxjs/toolkit";

import { StatusColours } from "./models";

export interface ToastState {
  open: boolean;
  message: string;
  status: StatusColours;
}

const initialState: ToastState = {
  open: false,
  message: "",
  status: "info"
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showSuccessAction(state, action) {
      return {
        open: true,
        message: action.payload,
        status: "success"
      };
    },
    showErrorAction(state, action) {
      return {
        open: true,
        message: action.payload,
        status: "error"
      };
    },
    showInfoAction(state, action) {
      return {
        open: true,
        message: action.payload,
        status: "info"
      };
    },
    closeToastAction(state) {
      return {
        ...state,
        open: false,
        message: ""
      };
    }
  }
});

const { actions, reducer } = toastSlice;
export const toastReducer = reducer;
export const {
  showSuccessAction,
  showErrorAction,
  showInfoAction,
  closeToastAction
} = actions;
