import { Box } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import MaterialTable, { Column } from "material-table";
import * as React from "react";
import { useSelector } from "react-redux";

import { Multiselect } from "../../../components/Multiselect";
import { tableIcons } from "../../../components/tableIcons";
import ApiClient from "../../../services/ApiClient/api-client-services";
import {
  LanguageId,
  TranslationContent,
  TranslationId,
  TranslationIdLookup,
  TranslationListResponse
} from "../model";
import { languagesSelector } from "../selectors";
import { AddTranslationCustomer } from "./AddTranslationCustomer";
import { AddTranslationLabel } from "./AddTranslationLabel";
import { AddTranslationLanguage } from "./AddTranslationLanguage";

const initialCols: Column<MTableRow>[] = [
  {
    field: "translation_label",
    title: "Label",
    editable: "never"
  }
];

interface ContentByLang {
  [key: string]: TranslationContent | any;
}

type MTableRow = ContentByLang & {
  translation_label_id: number;
  translation_label: string;
  translationIdLookup: TranslationIdLookup;
  tableData: {
    id: number;
  };
};

const GenericTranslations: React.FC<any> = props => {
  const { lookupLang } = useSelector(languagesSelector);
  const [selectedLang, setSelectedLang] = React.useState<number[]>(
    props.selectedLang
  );
  const {
    data: rawData,
    translationLoading,
    translationError
  } = props.translationsList;
  const translationRefetch = props.translationRefetch;
  const [data, setData] = React.useState<MTableRow[]>([]);
  const [columns, setColumns] = React.useState(initialCols);
  const updateTranslation = (translationId: TranslationId, text: string) =>
    ApiClient.editTranslation(translationId, text);
  const deleteTranslation = (translationId: TranslationId) =>
    ApiClient.deleteTranslation(translationId);

  const createTranslation = (
    label_id: number,
    language_id: number,
    text: string
  ) =>
    ApiClient.addTranslation({
      label_id,
      language_id,
      text,
      customer_id: 0
    });

  const onRowUpdate = (newData: MTableRow, oldData: MTableRow | undefined) => {
    if (oldData === undefined) {
      return Promise.reject();
    }

    const changedFields = selectedLang.filter(
      (languageId: number) =>
        newData[languageId.toString()] !== oldData[languageId.toString()]
    );

    const requests = changedFields.map((languageId: number) => {
      const text = newData[languageId.toString()];
      const translationId = newData.translationIdLookup
        ? newData.translationIdLookup[languageId]
        : undefined;

      if (translationId !== undefined && !text) {
        newData.translationIdLookup[languageId] = undefined;

        return deleteTranslation(translationId);
      }

      if (translationId !== undefined) {
        return updateTranslation(translationId, text);
      }

      return createTranslation(newData.translation_label_id, languageId, text);
    });

    return Promise.all(requests).then(values => {
      const newTranslations = values.reduce(
        (acc: TranslationIdLookup, { status, data, config }) => {
          if (status !== 201 || typeof data !== "number") {
            const translationId = data.ID ? data.ID : data.id;
            const languageId = data.LanguageID;

            if (
              translationId !== undefined &&
              languageId > 0 &&
              newData.translationIdLookup
            ) {
              newData.translationIdLookup[languageId] = translationId;
            }

            return acc;
          }

          const { language_id } = JSON.parse(config.data);

          if (typeof language_id === "number") {
            return {
              ...acc,
              [language_id]: data
            };
          }

          return acc;
        },
        {}
      );

      const dataUpdate = [...data];
      const row: MTableRow = {
        ...newData,
        translationIdLookup: {
          ...newData.translationIdLookup,
          ...newTranslations
        }
      };
      const index = oldData.tableData.id;
      dataUpdate[index] = row;
      setData(dataUpdate);
    });
  };

  const modelTranslations = (
    data: TranslationListResponse[],
    languages: LanguageId[]
  ): MTableRow[] => {
    const emptyLangFields = languages.reduce(
      (acc: ContentByLang, langId) => ({
        [langId.toString()]: "",
        ...acc
      }),
      {}
    );

    return data.map(row => {
      const { translations, ...rest } = row;

      const indexedTranslations = translations
        .filter(t => t.translation_customer_id === 0)
        .reduce(
          (
            acc: any,
            { translation_id, translation_language_id, translation_content }
          ) => ({
            ...acc,
            translationIdLookup: {
              ...acc.translationIdLookup,
              [translation_language_id]: translation_id
            },
            [translation_language_id.toString()]: translation_content
          }),
          {}
        );

      return { ...emptyLangFields, ...rest, ...indexedTranslations };
    });
  };

  const handleSelectedLang = (value: number[]) => {
    let valLanguage = value.length > 0 ? value : [4];
    setSelectedLang(valLanguage);
    props.onChange(valLanguage);
  };
  const handleAddedCustomer = () => {
    props.customerRefetch();
  };
  const handleAddedLabel = () => {
    translationRefetch();
  };

  React.useEffect(() => {
    const makeLangCol = (id: number, name: string): Column<MTableRow> => ({
      field: id.toString(),
      title: name && name.toUpperCase(),
      emptyValue: ""
    });

    if (selectedLang.length > 0 && Array.isArray(rawData)) {
      setData(modelTranslations(rawData, selectedLang));
      const languageCols = selectedLang.map(lang =>
        makeLangCol(lang, lookupLang[lang])
      );

      setColumns([...initialCols, ...languageCols]);
    }
  }, [rawData, selectedLang, lookupLang]);

  return (
    <>
      {translationError && translationError.response?.data}
      <Box display="flex" justifyContent="space-between" mb={3}>
        <div>
          <AddTranslationLabel onSave={handleAddedLabel} />
          <AddTranslationLanguage />
          <AddTranslationCustomer onSave={handleAddedCustomer} />
        </div>
        <Multiselect
          items={lookupLang}
          name="languages"
          label="Languages"
          value={selectedLang}
          onChange={handleSelectedLang}
        />
      </Box>
      <MaterialTable
        data={data}
        columns={columns}
        icons={tableIcons}
        isLoading={translationLoading}
        actions={[
          {
            icon: () => <RefreshIcon />,
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => {
              translationRefetch();
            }
          }
        ]}
        options={{
          actionsColumnIndex: -1,
          showTitle: false,
          pageSize: 20
        }}
        editable={{
          onRowUpdate
        }}
      />
    </>
  );
};

export default GenericTranslations;
