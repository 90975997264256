import { PersistedState } from "redux-persist";

const migrations = {
  0: (state: PersistedState) => undefined,
  1: (state: any) => {
    return {
      ...state,
      protected: {
        ...state.protected,
        isTokenExpired: false
      }
    };
  }
};

export default migrations;
