import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  form: {
    marginBottom: "20px",
    marginTop: "20px",

    "& .MuiTextField-root": {
      margin: "8px"
    }
  },
  status: {
    marginLeft: "8px"
  }
});

export default useStyles;
