import { Menu, MenuItem } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

interface DropDownMenuProps {
  anchorRef: any;
  menuItems: SubmenuState;
  handleClose: () => void;
}

interface SubmenuListState {
  label: string;
  url: string;
}

interface SubmenuState {
  list: SubmenuListState[];
}

export const DropDownMenuComponent: React.FC<DropDownMenuProps> = ({
  anchorRef,
  menuItems,
  handleClose
}) => {
  const submenu = menuItems.list.map(item => (
    <MenuItem key={item.url} component={Link} to={item.url}>
      {item.label}
    </MenuItem>
  ));
  return (
    <>
      <Menu
        anchorEl={anchorRef}
        keepMounted
        open={!!anchorRef}
        onClick={handleClose}
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {submenu}
      </Menu>
    </>
  );
};
