import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiClient from "../../services/ApiClient/api-client-services";
import { Language, Lookup } from "./model";

export interface LanguagesState {
  languageList: Language[];
  lookupLang: Lookup;
  defaultLanguage: number;
}

export const fetchLanguages = createAsyncThunk("languages/fetch", async () => {
  const response = await ApiClient.fetchLanguages();
  return response.data as Language[];
});

const listToLookup = (languages: Language[]) =>
  languages.reduce(
    (acc: Lookup, lang: Language) => ({
      [lang.id]: lang.language_name,
      ...acc
    }),
    {}
  );

const initialState: LanguagesState = {
  languageList: [],
  lookupLang: {},
  defaultLanguage: 4
};

export const languagesSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchLanguages.fulfilled, (state, { payload }) => {
      if (!Array.isArray(payload)) {
        return state;
      }
      const defaultLanguage = payload.find(
        ({ language_code }) => language_code === "nl"
      );

      return {
        languageList: payload,
        lookupLang: listToLookup(payload),
        defaultLanguage: defaultLanguage
          ? defaultLanguage.id
          : state.defaultLanguage
      };
    });
  }
});

export const { reducer: languagesReducer } = languagesSlice;
