import { Dispatch } from "redux";

import { AsyncDispatch } from "../../types/redux";
import {
  addDropoutAction,
  deleteDropoutAction,
  editDropoutAction,
  getDropoutsAction,
  getPolicyTypesAction
} from "./actions";
import { DropoutRawResponse, DropoutRequest } from "./models";
import { DropoutsState } from "./reducer";

export interface DispatchProps {
  addDropout: (dropout: DropoutRequest) => Promise<AsyncDispatch>;
  deleteDropout: (id: number) => Promise<AsyncDispatch>;
  editDropout: (dropout: DropoutRawResponse) => Promise<AsyncDispatch>;
  getDropouts: () => void;
  getPolicyTypes: () => void;
}

export interface StateProps extends DropoutsState {}

export const mapStateToProps = (dropoutsState: DropoutsState): StateProps => {
  return {
    dropouts: dropoutsState.dropouts,
    isLoading: dropoutsState.isLoading,
    policyTypes: dropoutsState.policyTypes
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  addDropout: dropout => dispatch(addDropoutAction(dropout)),
  deleteDropout: id => dispatch(deleteDropoutAction(id)),
  editDropout: dropout => dispatch(editDropoutAction(dropout)),
  getDropouts: () => {
    dispatch(getDropoutsAction());
  },
  getPolicyTypes: () => {
    dispatch(getPolicyTypesAction());
  }
});
