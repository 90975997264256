import { Box, Container, Typography } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import useAxios from "axios-hooks";
import MaterialTable from "material-table";
import * as React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { tableIcons } from "../../components/tableIcons";
import { loginSelector } from "../Login/selectors";

const MISSING_POLICIES_FILES = "policies/check/files";

const PolicyFilesComponent: React.FC<{}> = () => {
  const [{ data, loading, error }, refetch] = useAxios(MISSING_POLICIES_FILES);
  const { role } = useSelector(loginSelector);

  if (role !== "superadmin") {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Container>
      <Box my={3} display="flex" flexDirection="column" alignItems="center">
        <Typography align="center" variant="h4" component="h1">
          Check Policy Files
        </Typography>
        {error && <span>There was an error</span>}
      </Box>
      <MaterialTable
        columns={[
          {
            title: "Id",
            field: "id",
            width: "5%"
          },
          {
            title: "Name",
            field: "name"
          },
          {
            title: "File",
            field: "file"
          }
        ]}
        data={Array.isArray(data) ? data : []}
        icons={tableIcons}
        isLoading={loading}
        actions={[
          {
            icon: () => <RefreshIcon />,
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => {
              refetch();
            }
          }
        ]}
        options={{
          pageSize: 20,
          showTitle: false,
          tableLayout: "fixed"
        }}
      />
    </Container>
  );
};

export default PolicyFilesComponent;
