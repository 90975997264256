import { Button } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useState } from "react";

import useStyles from "./styles";

interface FileUploadProps {
  acceptedFiles?: string[];
  pending: boolean;
  filesLimit: number;
  handleUploadFiles: (files: File[]) => void;
}

export const FileUploadComponent: React.FC<FileUploadProps> = ({
  handleUploadFiles,
  acceptedFiles = [],
  pending,
  filesLimit
}) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState<File[]>([]);

  const handleChange = (files: File[]) => {
    setSelectedFile(files);
  };

  const handleSave = () => {
    handleUploadFiles(selectedFile);
  };

  return (
    <>
      <DropzoneArea
        maxFileSize={128 * 1000000}
        dropzoneText="Upload your file"
        showPreviewsInDropzone={false}
        showPreviews={true}
        onChange={handleChange}
        acceptedFiles={acceptedFiles}
        filesLimit={filesLimit}
      />
      <Button
        disabled={pending || selectedFile.length === 0}
        className={classes.button}
        onClick={handleSave}
      >
        {pending ? "Pending" : "Upload files"}
      </Button>
    </>
  );
};
