import { Box, Button, Container, Typography } from "@material-ui/core";
import useAxios from "axios-hooks";
import MaterialTable from "material-table";
import * as React from "react";

import { tableIcons } from "../../components/tableIcons";
import { INSURED_URL } from "../../services/ApiClient/urls";
import { Insurer } from "./models";
import useStyles from "./styles";

const InsuredComponent: React.FC<{}> = () => {
  const classes = useStyles();
  const [{ data: insurers, loading, error }] = useAxios<Insurer[]>(INSURED_URL);

  const columns = [
    { field: "id", title: "#" },
    {
      field: "firstName",
      title: "Name",
      render: (rowData: Insurer) => (
        <Button className={classes.link}>
          {rowData.firstName} {rowData.lastName}
        </Button>
      )
    },
    { field: "email", title: "Email" },
    { field: "phone", title: "Phone" }
  ];

  return (
    <Container>
      <Box my={4} display="flex" flexDirection="column" alignItems="center">
        <Typography align="center" variant="h4" component="h1">
          Insurers
        </Typography>
        {error && <span>There was an error</span>}
      </Box>
      <MaterialTable
        data={Array.isArray(insurers) ? insurers : []}
        columns={columns}
        icons={tableIcons}
        isLoading={loading}
        options={{
          pageSize: 20,
          showTitle: false,
          tableLayout: "fixed"
        }}
      />
    </Container>
  );
};

export default InsuredComponent;
