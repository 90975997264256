import jwt from "jsonwebtoken";

interface UserData {
  email: string;
  firstName: string;
  lastName: string;
}

export interface DecodeToken {
  payload: {
    data: UserData;
    exp: number;
  };
}

export const verifyToken = (token: string, publicKey: string) => {
  const unverifiedPayload = jwt.decode(token);
  const currentTimestamp = new Date().getTime();

  try {
    const decoded: any = jwt.verify(token, publicKey, {
      ignoreNotBefore: true,
      clockTolerance: 3
    });
    console.log({
      nbf: decoded.nbf,
      currentTimestamp
    });
    return {
      success: true,
      ...decoded
    };
  } catch (err) {
    console.log({ err, unverifiedPayload, currentTimestamp });
    return {
      success: false,
      ...err
    };
  }
};
