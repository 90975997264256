import { makeStyles } from "@material-ui/core";
import { blue, green, red } from "@material-ui/core/colors";

const statusColors = {
  success: green[700],
  error: red[700],
  info: blue[700]
};

const useStyles = makeStyles({
  success: {
    backgroundColor: statusColors.success
  },
  info: {
    backgroundColor: statusColors.info
  },
  error: {
    backgroundColor: statusColors.error
  }
});

export default useStyles;
