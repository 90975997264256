import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "recompose";
import { createSelector } from "reselect";

import { protectedComponentHoc } from "../../components/ProtectedComponent";
import {
  DamageCostsComponent,
  DamageCostsInnerProps,
  DamageCostsProps
} from "./component";
import { mapDispatchToProps, mapStateToProps } from "./redux";
import { damageCostsSelector } from "./selectors";
import { DamageCostsStyleRules } from "./styles";

export const DamageCosts = compose<DamageCostsInnerProps, DamageCostsProps>(
  connect(
    createSelector(damageCostsSelector, mapStateToProps),
    mapDispatchToProps
  ),
  withStyles(DamageCostsStyleRules),
  protectedComponentHoc
)(DamageCostsComponent);
