import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";

import useStyles from "./styles";

export interface LookupItem {
  [id: number]: string;
}

interface MultiselectProps {
  items: LookupItem;
  name: string;
  label: string;
  value: number[];
  onChange: (value: any) => void;
}

export const MultiselectComponent: React.FC<MultiselectProps> = ({
  items,
  name,
  value,
  label,
  onChange
}) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as number[]);
  };

  const itemList = Object.entries(items).map(([key, name]) => {
    const id = parseInt(key);
    return (
      <MenuItem key={id} value={id}>
        <Checkbox checked={value.indexOf(id) !== -1} />
        <ListItemText primary={name} />
      </MenuItem>
    );
  });

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        id={name}
        multiple
        value={value}
        onChange={handleChange}
        input={<Input />}
        renderValue={selected =>
          (selected as number[])
            .map((id: number): string => items[id])
            .join(", ")
        }
      >
        {itemList}
      </Select>
    </FormControl>
  );
};
