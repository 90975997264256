import { createStyles } from "@material-ui/core";

export type DropoutsClassKey = "pageTitle" | "select";

export const DropoutsStyleRules = createStyles({
  pageTitle: {
    marginTop: "40px"
  },
  select: {
    marginTop: "20px",
    maxWidth: "130px",
    width: "100%",
    marginLeft: "15px"
  }
});
