import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  form: {
    marginBottom: 20
  },
  formControl: {
    minWidth: 200,
    marginLeft: 20
  }
});

export default useStyles;
