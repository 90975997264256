import { createAction } from "redux-actions";

import ApiClient from "../../services/ApiClient/api-client-services";
import { Action } from "../../types/redux";
import { DamageCostRequest, DamageCostResponse } from "./models";

export enum ActionTypePrefixes {
  DAMAGE_COSTS = "DAMAGE_COSTS",
  ADD_DAMAGE_COSTS = "ADD_DAMAGE_COSTS",
  EDIT_DAMAGE_COSTS = "EDIT_DAMAGE_COSTS",
  DELETE_DAMAGE_COSTS = "DELETE_DAMAGE_COSTS"
}

export enum ActionTypeKeys {
  DAMAGE_COSTS_PENDING = "DAMAGE_COSTS_PENDING",
  DAMAGE_COSTS_FULFILLED = "DAMAGE_COSTS_FULFILLED",
  DAMAGE_COSTS_REJECTED = "DAMAGE_COSTS_REJECTED",
  ADD_DAMAGE_COSTS_PENDING = "ADD_DAMAGE_COSTS_PENDING",
  ADD_DAMAGE_COSTS_FULFILLED = "ADD_DAMAGE_COSTS_FULFILLED",
  ADD_DAMAGE_COSTS_REJECTED = "ADD_DAMAGE_COSTS_REJECTED",
  EDIT_DAMAGE_COSTS_PENDING = "EDIT_DAMAGE_COSTS_PENDING",
  EDIT_DAMAGE_COSTS_FULFILLED = "EDIT_DAMAGE_COSTS_FULFILLED",
  EDIT_DAMAGE_COSTS_REJECTED = "EDIT_DAMAGE_COSTS_REJECTED",
  DELETE_DAMAGE_COSTS_PENDING = "DELETE_DAMAGE_COSTS_PENDING",
  DELETE_DAMAGE_COSTS_FULFILLED = "DELETE_DAMAGE_COSTS_FULFILLED",
  DELETE_DAMAGE_COSTS_REJECTED = "DELETE_DAMAGE_COSTS_REJECTED"
}

interface DamageCostsPayload {
  data: DamageCostResponse[];
}

interface DamageCostPayload {
  data: DamageCostResponse;
}

interface EditDamageCostPayload {
  data: number[];
  config: {
    data: string;
  };
}

const getDamageCostsExecutor = (): Promise<DamageCostResponse[]> =>
  ApiClient.loadRepairCosts();

const addDamageCostExecutor = (
  dmageCost: DamageCostRequest
): Promise<DamageCostRequest> => ApiClient.addRepairCost(dmageCost);

const editDamageCostExecutor = (
  id: number,
  dmageCost: DamageCostRequest
): Promise<DamageCostRequest> => ApiClient.updateRepairCost(id, dmageCost);

const deleteDamageCostExecutor = (dmageCostId: string): Promise<string> =>
  ApiClient.deleteRepairCost(dmageCostId);

export const getDamageCostsAction = createAction(
  ActionTypePrefixes.DAMAGE_COSTS,
  getDamageCostsExecutor
);

export const addDamageCostsAction = createAction(
  ActionTypePrefixes.ADD_DAMAGE_COSTS,
  addDamageCostExecutor
);

export const editDamageCostsAction = createAction(
  ActionTypePrefixes.EDIT_DAMAGE_COSTS,
  editDamageCostExecutor
);

export const deleteDamageCostsAction = createAction(
  ActionTypePrefixes.DELETE_DAMAGE_COSTS,
  deleteDamageCostExecutor
);

export type ActionTypes =
  | Action<ActionTypeKeys.DAMAGE_COSTS_PENDING, undefined>
  | Action<ActionTypeKeys.DAMAGE_COSTS_FULFILLED, DamageCostsPayload>
  | Action<ActionTypeKeys.DAMAGE_COSTS_REJECTED, DamageCostsPayload>
  | Action<ActionTypeKeys.ADD_DAMAGE_COSTS_PENDING, undefined>
  | Action<ActionTypeKeys.ADD_DAMAGE_COSTS_FULFILLED, DamageCostPayload>
  | Action<ActionTypeKeys.ADD_DAMAGE_COSTS_REJECTED, DamageCostPayload>
  | Action<ActionTypeKeys.EDIT_DAMAGE_COSTS_PENDING, undefined>
  | Action<ActionTypeKeys.EDIT_DAMAGE_COSTS_FULFILLED, EditDamageCostPayload>
  | Action<ActionTypeKeys.EDIT_DAMAGE_COSTS_REJECTED, DamageCostPayload>
  | Action<ActionTypeKeys.DELETE_DAMAGE_COSTS_PENDING, undefined>
  | Action<ActionTypeKeys.DELETE_DAMAGE_COSTS_FULFILLED, DamageCostPayload>
  | Action<ActionTypeKeys.DELETE_DAMAGE_COSTS_REJECTED, DamageCostPayload>;
