import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import promise from "redux-promise-middleware";
import thunkMiddleware from "redux-thunk";

import { rootReducer } from "./reducers";
import migrations from "./redux/migrations";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["importPage"],
  migrate: createMigrate(migrations, { debug: false })
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const configureStore = () => {
  const composeEnhancers = composeWithDevTools({});
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(promise, thunkMiddleware))
  );
  let persistor = persistStore(store as any);
  return { store, persistor };
};

export const configuredStore = configureStore();
export const { store, persistor } = configuredStore;
