import { Action } from "../types/redux";
import { PublicKeyResponse } from "../types/responseTypes";

export enum ActionTypePrefixes {
  EXCHANGE_TOKEN = "EXCHANGE_TOKEN",
  LOGIN = "LOGIN",
  REQUEST_PUBLIC_KEY = "REQUEST_PUBLIC_KEY"
}

export enum ActionTypeKeys {
  EXCHANGE_TOKEN_PENDING = "EXCHANGE_TOKEN_PENDING",
  EXCHANGE_TOKEN_FULFILLED = "EXCHANGE_TOKEN_FULFILLED",
  EXCHANGE_TOKEN_REJECTED = "EXCHANGE_TOKEN_REJECTED",
  LOGIN_PENDING = "LOGIN_PENDING",
  LOGIN_FULFILLED = "LOGIN_FULFILLED",
  LOGIN_REJECTED = "LOGIN_REJECTED",
  LOGOUT = "LOGOUT",
  REQUEST_PUBLIC_KEY_PENDING = "REQUEST_PUBLIC_KEY_PENDING",
  REQUEST_PUBLIC_KEY_FULFILLED = "REQUEST_PUBLIC_KEY_FULFILLED",
  REQUEST_PUBLIC_KEY_REJECTED = "REQUEST_PUBLIC_KEY_REJECTED",
  VERIFY_TOKEN = "VERIFY_TOKEN",
  EXPIRE_TOKEN = "EXPIRE_TOKEN"
}

interface LoginPayload {
  data: LoginResponse;
}

interface LoginResponse {
  token: string;
  refreshToken: string;
  message: string;
  status: number;
}

interface ExchangeTokenPayload {
  data: ExchangeTokenResponse;
}

interface ExchangeTokenResponse {
  token: string;
  refreshToken: string;
  message: string;
}

interface PublicKeyPayload {
  data: PublicKeyResponse[];
}

interface DecodedToken {
  success: true;
  data: {
    role: string;
  };
}

interface TokenError {
  success: false;
  name: string;
}

export type VerifyTokenPayload = DecodedToken | TokenError;

export type ActionTypes =
  | Action<ActionTypeKeys.EXCHANGE_TOKEN_PENDING, ExchangeTokenPayload>
  | Action<ActionTypeKeys.EXCHANGE_TOKEN_FULFILLED, ExchangeTokenPayload>
  | Action<ActionTypeKeys.EXCHANGE_TOKEN_REJECTED, ExchangeTokenPayload>
  | Action<ActionTypeKeys.LOGIN_PENDING, LoginPayload>
  | Action<ActionTypeKeys.LOGIN_FULFILLED, LoginPayload>
  | Action<ActionTypeKeys.LOGIN_REJECTED, LoginPayload>
  | Action<ActionTypeKeys.LOGOUT, undefined>
  | Action<ActionTypeKeys.REQUEST_PUBLIC_KEY_PENDING, PublicKeyPayload>
  | Action<ActionTypeKeys.REQUEST_PUBLIC_KEY_FULFILLED, PublicKeyPayload>
  | Action<ActionTypeKeys.REQUEST_PUBLIC_KEY_REJECTED, PublicKeyPayload>
  | Action<ActionTypeKeys.VERIFY_TOKEN, VerifyTokenPayload>
  | Action<ActionTypeKeys.EXPIRE_TOKEN, undefined>;
