import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import useAxios from "axios-hooks";
import { DropzoneArea } from "material-ui-dropzone";
import * as React from "react";
import { useDispatch } from "react-redux";

import { ClearTranslationsCache } from "../../components/ClearTranslationsCache";
import {
  showErrorAction,
  showSuccessAction
} from "../../components/Toast/toastSlice";
import { Customer } from "../../services/ApiClient/models";
import {
  CUSTOMERS_URL,
  UPLOAD_TRANSLATIONS
} from "../../services/ApiClient/urls";
import useStyles from "./styles";

const ImportTranslationsComponent: React.FC<{}> = () => {
  const classes = useStyles();
  const acceptedFiles: string[] = [];
  const filesLimit = 1;

  const [
    { data: customerList = [], loading: customerLoading, error: customerError }
  ] = useAxios<Customer[]>(CUSTOMERS_URL);

  const [{ loading: pendingUpload }, postTranslations] = useAxios(
    {
      url: UPLOAD_TRANSLATIONS,
      method: "POST"
    },
    { manual: true }
  );

  const [customer, setCustomer] = React.useState("");
  const [globalTranslations, setGlobalTranslations] = React.useState(false);
  const [file, setFile] = React.useState<File | undefined>(undefined);

  const dispatch = useDispatch();

  const uploadTranslations = (data: FormData) => {
    postTranslations({ data })
      .then(({ data: { message } }) => {
        if (message) {
          dispatch(showSuccessAction(message));
        }
      })
      .catch(error => {
        const errorMessage = error?.response?.data?.message;
        if (errorMessage) {
          dispatch(showErrorAction(errorMessage));
        }
      });
  };

  const handleUpload = (event: React.FormEvent) => {
    event.preventDefault();
    if (file === undefined) {
      return;
    }

    const data = new FormData();
    Object.entries({
      customer,
      file,
      global: globalTranslations.toString()
    }).forEach(([key, value]) => {
      data.append(key, value);
    });
    uploadTranslations(data);
  };

  const handleGlobalTranslationsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGlobalTranslations(event.target.checked);
  };

  const handleFileChange = (files: File[]) => {
    setFile(files[0]);
  };

  const renderCustomers = (options: Customer[]) =>
    options.map(({ ID, customer_code, customer_name }) => (
      <MenuItem key={ID} value={customer_code}>
        {customer_name}
      </MenuItem>
    ));

  React.useEffect(() => {
    if (globalTranslations) {
      setCustomer("");
    }
  }, [globalTranslations]);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={0}
    >
      <Grid item xs={6}>
        <Typography
          className={classes.pageTitle}
          gutterBottom
          align="center"
          variant="h4"
          component="h5"
        >
          Import Translations
        </Typography>
        <form onSubmit={handleUpload}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={globalTranslations}
                  onChange={handleGlobalTranslationsChange}
                  name="globalTranslations"
                  color="primary"
                />
              }
              label="Global Translations"
            />
          </Box>
          {customerLoading ? (
            <Box textAlign="center" mb={3}>
              <CircularProgress />
            </Box>
          ) : (
            <FormControl
              required={!globalTranslations}
              className={classes.formControl}
            >
              <InputLabel id="customer-label">Customer</InputLabel>
              <Select
                labelId="customer-label"
                id="customer"
                disabled={globalTranslations}
                value={customer}
                onChange={event => {
                  setCustomer(event.target.value as string);
                }}
              >
                <MenuItem value="">None</MenuItem>
                {renderCustomers(customerList)}
              </Select>
            </FormControl>
          )}
          {customerError && <span>Could not load customers</span>}
          <DropzoneArea
            maxFileSize={128 * 1000000}
            dropzoneText="Upload your file"
            showPreviewsInDropzone={false}
            showPreviews={true}
            onChange={handleFileChange}
            acceptedFiles={acceptedFiles}
            filesLimit={filesLimit}
          />
          <input
            disabled={pendingUpload || file === undefined}
            className={classes.button}
            type="submit"
            value={pendingUpload ? "Pending" : "Upload files"}
          />
        </form>
        <Box textAlign="center" my={2}>
          <ClearTranslationsCache />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ImportTranslationsComponent;
