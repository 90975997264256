import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  form: {
    padding: 20
  },
  button: {
    marginTop: 10
  }
});

export default useStyles;
