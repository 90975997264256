import { connect } from "react-redux";
import { compose } from "recompose";
import { createSelector } from "reselect";

import { ProtectedComponent } from "./component";
import { mapDispatchToProps, mapStateToProps } from "./redux";
import { protectedSelector } from "./selectors";

export const protectedComponentHoc = compose(
  connect(
    createSelector(protectedSelector, mapStateToProps),
    mapDispatchToProps
  ),
  ProtectedComponent
);
