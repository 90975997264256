import { createAction } from "redux-actions";

import ApiClient from "../services/ApiClient/api-client-services";
import { verifyToken } from "../services/Token";
import { PublicKeyResponse } from "../types/responseTypes";
import {
  ActionTypeKeys,
  ActionTypePrefixes,
  VerifyTokenPayload
} from "./types";

const exchangeTokenExecutor = (refreshToken: string) =>
  ApiClient.exchangeToken(refreshToken);

export const exchangeTokenAction = createAction(
  ActionTypePrefixes.EXCHANGE_TOKEN,
  exchangeTokenExecutor
);

const loginExecutor = (username: string, password: string) =>
  ApiClient.requestAuth(username, password);

export const loginAction = createAction(
  ActionTypePrefixes.LOGIN,
  loginExecutor
);

export const logoutAction = createAction(ActionTypeKeys.LOGOUT);

const requestPublicKeyExecutor = (): Promise<PublicKeyResponse[]> =>
  ApiClient.requestPublicKey();

export const requestPublicKeyAction = createAction(
  ActionTypePrefixes.REQUEST_PUBLIC_KEY,
  requestPublicKeyExecutor
);

const verifyTokenExecutor = (
  token: string,
  publicKey: string
): Promise<VerifyTokenPayload> => verifyToken(token, publicKey);

export const verifyTokenAction = createAction(
  ActionTypeKeys.VERIFY_TOKEN,
  verifyTokenExecutor
);

export const expireAction = createAction(ActionTypeKeys.EXPIRE_TOKEN);
